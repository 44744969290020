

<template>
  <div class="stationView">
    <main class="player-content flex-row">
      <section class="flex-1">
        <div class="flex-autorow flex-top flex-stretch player-channel">
          <!-- station details -->
<div class="flex-item flex-1">
<div class="push-bottom">
  <div class="card flex-item flex-top flex-stretch fx fx-slide-up fx-delay-4 flex-1">
        <div class="track-artwork">
      </div>
    <div class="track-artwork">
      <a :href="config.meta.url"><img id="coverArt" alt="Share" :src="'https://chart.googleapis.com/chart?cht=qr&chl='+config.meta.url+'&chs=180x180&choe=UTF-8&chld=L|2'" class="fx fx-fade-in"></a>
      </div>
      </div>
      </div>
      </div>

<div class="flex-item flex-1 card fx flex-row flex-middle flex-space fx-slide-left fx-delay-2">
  <a v-if="config.facebook.url" :href="config.facebook.url" style="width:7%"><img style="filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);" src="/img/social/facebook.svg"></a>
  <a v-if="config.instagram.url" :href="config.instagram.url" style="width:7%"><img style="filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);" src="/img/social/instagram.svg"></a>
  <a v-if="config.twitter.url" :href="config.twitter.url" style="width:7%"><img style="filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);" src="/img/social/twitter.svg"></a>
  <a v-if="config.youtube.url" :href="config.youtube.url" style="width:7%"><img style="filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);" src="/img/social/youtube.svg"></a>
  </div>

        </div>

        <!--<syncLyrics></syncLyrics>-->

      </section>
    </main>

  </div>
</template>

<script>
    /**
     * Home
     */
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'share',
        data: () => {
            return {
                // toggles
                visible: false,
                sidebar: false,
                errors: {},

            }
        },
  props: {
    config : Object,
  },

    }
</script>